import axios from "axios";

class ProjectsAPI {
  constructor(root, auth) {
    this.root = root;
    this.auth = auth;
  }

  getProjects() {
    return axios
      .get(this.root + "/api/projects/", {
        headers: this.auth.getHeaders(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getProjectInfo(name) {
    return axios
      .get(this.root + "/api/projects/" + name)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export default ProjectsAPI;

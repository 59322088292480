class Navigation {
  constructor(sections, auth) {
    this.sections = {};
    this.auth = auth;
    for (let section of sections) {
      this.sections[section.key] = section;
    }

    this.location = undefined;
    this.path = [];
    this.callbacks = [];

    this._navigate_to_current_location();
    window.history.replaceState(null, this.location.title, window.location.url);
    let self = this;
    window.addEventListener("popstate", () => {
      self._navigate_to_current_location();
    });
  }

  navigate(key, path) {
    if (key === undefined) key = this.location.key;
    if (path === undefined) path = [];
    this._navigate(key, path);
    let url = "/" + key;
    if (path.length > 0) url += "/" + path.join("/");
    if (key === "index" && path.length == 0) url = "/";
    window.history.pushState(null, this.location.title, url);
    window.title = this.location.title;
    this._notify();
  }

  getLocation() {
    return this.location;
  }

  getPath() {
    return this.path;
  }

  subscribe(callback) {
    this.callbacks.push(callback);
    callback(this.location, this.path);
  }

  unsubscribe(callback) {
    this.callbacks = this.callbacks.filter((x) => x !== callback);
  }

  getSection(key) {
    return this.sections[key];
  }

  _navigate_to_current_location() {
    let path = window.location.pathname.substring(1).split("/");
    this._navigate(path[0], path.slice(1));
    window.title = this.location.title;
    this._notify();
  }

  _navigate(key, path) {
    if (key === "") key = "index";
    let new_location = this.sections[key];

    if (new_location === undefined) {
      this.location = this.sections["not_found"];
      console.log("Page not found: " + key);
    } else {
      this.location = new_location;
      this.path = path;
      console.log(
        "Navigating to " +
          this.location.title +
          " with path: /" +
          this.path.join("/")
      );
    }
  }

  _notify() {
    for (let callback of this.callbacks) {
      callback(this.location, this.path);
    }
  }
}

export default Navigation;

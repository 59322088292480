import { createApp } from "vue";
import App from "./App.vue";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import "@/css/bootstrap-dark.min.css";
import "@/css/custom.css";

import Navigation from "@/apis/navigation";
import AuthAPI from "@/apis/auth";
import NotesAPI from "@/apis/notes";
import TelewordsAPI from "@/apis/telewords";
import QuotesAPI from "@/apis/quotes";
import StatCounterAPI from "@/apis/statcounter";
import ProjectsAPI from "@/apis/projects";

const ROOT = process.env.NODE_ENV === "production" ? "" : "https://kribesk.dev";
const SECTIONS = [
  { key: "index", title: "Home", component: () => import("@/sections/Home") },
  { key: "login", title: "Login", component: () => import("@/sections/Login") },
  { key: "cv", title: "CV", component: () => import("@/sections/CV") },
  {
    key: "contacts",
    title: "Contacts",
    component: () => import("@/sections/Contacts"),
  },
  {
    key: "projects",
    title: "Projects",
    component: () => import("@/sections/Projects"),
    private: true,
  },
  {
    key: "algo-2015",
    title: "Algo-2015",
    component: () => import("@/sections/AlgoLections"),
  },
  {
    key: "charts",
    title: "GitHub charts",
    component: () => import("@/sections/Charts"),
    private: true,
  },
  {
    key: "telewords",
    title: "Telewords bot",
    component: () => import("@/sections/Telewords"),
    private: true,
  },
  {
    key: "notes",
    title: "Notes",
    component: () => import("@/sections/Notes"),
    private: true,
  },
  {
    key: "quotes",
    title: "Quotes",
    component: () => import("@/sections/Quotes"),
    private: true,
  },
  {
    key: "not_found",
    title: "Not Found",
    component: () => import("@/sections/NotFound"),
  },
];

if (process.env.NODE_ENV !== "production") {
  SECTIONS.push({
    key: "sandbox",
    title: "SANDBOX",
    component: () => import("@/sections/Sandbox"),
  });
}

const auth = new AuthAPI(ROOT);
const navigation = new Navigation(SECTIONS, auth);

const apis = {
  auth: auth,
  navigation: navigation,
  statcounter: new StatCounterAPI(navigation, auth),
  notes: new NotesAPI(ROOT, auth),
  telewords: new TelewordsAPI(ROOT, auth),
  quotes: new QuotesAPI(ROOT, auth),
  projects: new ProjectsAPI(ROOT, auth),
};

const app = createApp(App);
app.config.globalProperties.navigation = navigation;
app.config.globalProperties.apis = apis;
app.mount("#app");

<template>
  <div class="text-center">
    <div
      class="spinner-border mt-5"
      role="status"
      style="width: 3rem; height: 3rem"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
};
</script>

<style scoped></style>

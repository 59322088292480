import axios from "axios";

class TelewordsAPI {
  constructor(root, auth) {
    this.root = root;
    this.auth = auth;
  }

  getUsers() {
    let self = this;
    return axios
      .get(self.root + "/api/telewords/users", {
        headers: self.auth.getHeaders(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  }

  sendAnnouncement(username, message) {
    let self = this;
    return axios
      .post(
        self.root + "/api/telewords/announce",
        {
          username: username,
          message: message,
        },
        {
          headers: self.auth.getHeaders(),
        }
      )
      .catch((error) => {
        console.log(error);
        return [];
      });
  }
}

export default TelewordsAPI;

<template>
  <body>
    <header>
      <navigator
        title="Kribesk.dev"
        v-bind:sections="['index', 'cv', 'contacts', 'projects']"
        v-bind:sections-right="[
          'algo-2015',
          'quotes',
          'notes',
          'telewords',
          'charts',
          'login',
        ]"
      />
    </header>

    <main role="main">
      <display />
    </main>

    <footer class="footer">
      <div class="container">
        <p class="text-muted">© 2015–2023 Kribesk</p>
      </div>
    </footer>
  </body>
</template>

<script>
import Navigator from "@/navigation/Navigator";
import Display from "@/navigation/Display";

export default {
  name: "App",
  components: {
    Display,
    Navigator,
  },
};
</script>

<style></style>

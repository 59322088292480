<template>
  <nav class="navbar navbar-expand-lg navbar-light fixed-top">
    <a class="navbar-brand mr-3" href="#">
      {{ title }}
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li
          class="nav-item"
          v-for="item in menuLeft"
          v-bind:key="item.key"
          :class="{ active: item.active }"
        >
          <a class="nav-link" v-bind:href="item.href" v-on:click="navigate">
            {{ item.label }}
          </a>
        </li>
      </ul>

      <ul class="navbar-nav navbar-right">
        <li
          class="nav-item"
          v-for="item in menuRight"
          v-bind:key="item.key"
          :class="{ active: item.active }"
        >
          <a class="nav-link" v-bind:href="item.href" v-on:click="navigate">
            {{ item.label }}
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import $ from "jquery";

export default {
  name: "Navigator",
  props: ["sections", "sectionsRight", "title"],
  data() {
    return {
      show_private: this.apis.auth.getUsername() !== undefined,
      current: this.apis.navigation.getLocation().key,
    };
  },
  computed: {
    menuLeft() {
      return this.menu(this.sections);
    },
    menuRight() {
      return this.menu(this.sectionsRight);
    },
  },
  methods: {
    navigate(event) {
      event.preventDefault();
      $("#navbarSupportedContent").collapse("hide");

      let anchor = event.target.closest("a");
      if (!anchor) return;
      let key = anchor.getAttribute("href");

      this.navigation.navigate(key);
    },
    menu(sections) {
      let result = [];
      for (let key of sections) {
        let section = this.navigation.getSection(key);
        if (section.private && !this.show_private) continue;

        let label = section.title;
        if (section.key === "login" && this.show_private) {
          label = "@" + this.apis.auth.getUsername();
        }

        result.push({
          key: section.key,
          label: label,
          href: section.key,
          active: section.key === this.current,
        });
      }
      return result;
    },
  },
  created() {
    let navigator = this;
    this.apis.navigation.subscribe((section) => {
      navigator.current = section.key;
    });
    this.apis.auth.subscribe((username) => {
      this.show_private = username !== undefined;
    });
  },
};
</script>

<style scoped>
.navbar {
  background-color: rgba(255, 255, 255, 0.65);
  backdrop-filter: saturate(1.8) blur(20px);
}

.navbar-brand {
  font-family: "UnifrakturMaguntia";
  font-size: 22pt;
}

@media (prefers-color-scheme: dark) {
  .navbar {
    background-color: rgba(25, 29, 33, 0.65);
  }
}
</style>

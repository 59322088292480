import axios from "axios";

class QuotesAPI {
  constructor(root, auth) {
    this.root = root;
    this.auth = auth;
  }

  getQuotes() {
    return axios
      .get(this.root + "/api/quotes/random", {
        headers: this.auth.getHeaders(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  }
}

export default QuotesAPI;

import axios from "axios";

class AuthAPI {
  constructor(root) {
    this.root = root;
    this.token = getCookie("SESSION");
    this.username = getCookie("USERNAME");
    if (process.env.NODE_ENV !== "production") {
      this.username = "developer";
      this.token = "TOKEN_HERE";
    }
    this.callbacks = [];
  }

  login(username, password) {
    let self = this;
    return axios
      .post(self.root + "/api/login", {
        username: username,
        password: password,
      })
      .then((response) => {
        setCookie("SESSION", response.data.token, 14);
        setCookie("USERNAME", username, 14);
        self.token = response.data.token;
        self.username = username;
        this._notify();
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  logout() {
    setCookie("SESSION", "", -1);
    setCookie("USERNAME", "", -1);
    this.token = undefined;
    this.username = undefined;
    this._notify();
  }

  getUsername() {
    if (this.token !== undefined) {
      return this.username;
    } else {
      return undefined;
    }
  }

  getHeaders() {
    if (this.token !== undefined) {
      return { Authorization: this.token };
    } else {
      return {};
    }
  }

  subscribe(callback) {
    this.callbacks.push(callback);
  }

  unsubscribe(callback) {
    this.callbacks = this.callbacks.filter((x) => x !== callback);
  }

  _notify() {
    for (let callback of this.callbacks) {
      callback(this.username);
    }
  }
}

function setCookie(name, value, ttl_days) {
  const d = new Date();
  d.setTime(d.getTime() + ttl_days * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

function getCookie(name) {
  let label = name + "=";
  for (let chunk of decodeURIComponent(document.cookie).split(";")) {
    while (chunk.charAt(0) === " ") {
      chunk = chunk.substring(1);
    }
    if (chunk.indexOf(label) === 0) {
      return chunk.substring(label.length, chunk.length);
    }
  }
  return undefined;
}

export default AuthAPI;

class StatCounterAPI {
  constructor(navigation, auth) {
    this.navigation = navigation;
    this.auth = auth;

    let self = this;
    this.navigation.subscribe((location) => {
      self.record(location);
    });
  }

  record(location) {
    if (this.auth.getUsername() === "kribesk") {
      return;
    }
    if (process.env.NODE_ENV === "production") {
      window._statcounter.record_pageview();
    } else {
      console.log("Not sending location to statcounter" + location.key);
    }
  }
}

export default StatCounterAPI;

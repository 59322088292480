import axios from "axios";

class NotesAPI {
  constructor(root, auth) {
    this.root = root;
    this.auth = auth;
  }

  getNotes() {
    let self = this;
    return axios
      .get(self.root + "/api/notes", {
        headers: self.auth.getHeaders(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  }

  getNote(id) {
    let self = this;
    return axios
      .get(self.root + "/api/notes/" + id, {
        headers: self.auth.getHeaders(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return undefined;
      });
  }

  updateNote(id, title, content, is_public) {
    let self = this;
    return axios
      .put(
        self.root + "/api/notes/" + id,
        {
          title: title,
          content: content,
          is_public: is_public,
        },
        {
          headers: self.auth.getHeaders(),
        }
      )
      .then(() => {
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  deleteNote(id) {
    let self = this;
    return axios
      .delete(self.root + "/api/notes/" + id, {
        headers: self.auth.getHeaders(),
      })
      .then(() => {
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  createNote() {
    let self = this;
    return axios
      .post(
        self.root + "/api/notes",
        {
          title: "New note",
          content: "",
          is_public: false,
        },
        {
          headers: self.auth.getHeaders(),
        }
      )
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log(error);
        return undefined;
      });
  }
}

export default NotesAPI;

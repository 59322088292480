<template>
  <div class="text-general">
    <div class="alert alert-danger" role="alert">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorBox",
  props: ["message"],
};
</script>

<style scoped></style>

<template>
  <div class="container">
    <Transition name="fade" mode="out-in">
      <component v-bind:is="component" v-bind:error="error" />
    </Transition>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner";
import ErrorBox from "@/components/ErrorBox";

let COMPONENT_CACHE = {};

export default {
  name: "Display",
  data() {
    return {
      section: undefined,
      status: "load",
      error: "",
    };
  },
  computed: {
    component() {
      if (this.status === "ready") return COMPONENT_CACHE[this.section.key];
      else if (this.status === "error") return ErrorBox;
      else return Spinner;
    },
  },
  methods: {
    navigate(key) {
      if (this.section !== undefined && this.section.key === key) return;
      this.status = "load";
      this.section = this.navigation.sections[key];
      let self = this;
      this.section
        .component()
        .then((m) => {
          const { default: component } = m;
          COMPONENT_CACHE[key] = component;
          self.status = "ready";
          console.log("Load complete: " + m);
        })
        .catch((error) => {
          console.log(error);
          self.status = "error";
          self.error = "Page load failed";
        });
    },
  },
  created() {
    let self = this;
    this.navigation.subscribe((section) => {
      self.navigate(section.key);
    });
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
